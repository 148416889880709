import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import image3 from "images/umoya/SOLARIUM/3.jpeg";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import { useHistory, useLocation, useParams } from "react-router-dom";

export interface ListingStayPageProps {
  className?: string;
}

export interface HomeParamsProps {
  referente?: string;
  codice_sconto?: string;
  id?: string;
}

const tabs = [
  { index: 1, active: false, label: "Testo personalizzabile" },
  { index: 2, active: false, label: "Servizi" }
]

const ListingStayPageHome: FC<ListingStayPageProps> = ({ className = "" }) => {
  const history = useHistory();
  const location = useLocation<HomeParamsProps>();
  const temp = useParams<{ referente: string, codice_sconto: string, id: string }>();

  React.useEffect(() => {
    console.log(temp)
    if (temp?.referente) {
      sessionStorage.setItem("referente", temp.referente);
      sessionStorage.setItem("codice_sconto", temp.codice_sconto);
      history.push({
        pathname: `/dettaglio/${temp.id}`

      });
      // window.location.href = "http://prenotazioni.umoya.it/dettaglio/"+temp.id;
    }
  }, [temp]);


  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Umoya | Prenotazioni</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">

        <SectionHeroArchivePage
          currentPage="Stays"
          currentTab="Stays"
          className="pt-10 mb-80 lg:pb-10 lg:pt-15 "
          label="home"
        />

      </div>
    </div>
  );
};

export default ListingStayPageHome;
